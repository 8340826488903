<template>
  <div class="admin-franquicias">
    <TablaAdminFranquicias></TablaAdminFranquicias>
  </div>
</template>

<script>
export default {
  components: {
    TablaAdminFranquicias: () =>
      import('@/components/terceros/admin/TablaAdminFranquicias.vue'),
  },
};
</script>

<style></style>
